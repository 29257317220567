import React from 'react';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../../../components/shared/layout';
import SEO from '../../../components/SEO/SEO';
import styled from '@emotion/styled';
import { wrapper1200, noFeaturedWrapper, ContentStyles, color, wrapper, breakpoints } from '../../../utils/style';
import Img from 'gatsby-image';
import Breadcrumbs from '../../../components/shared/Breadcrumbs';

const WrapperDesktop = styled('div')`
  ${wrapper1200}
`;

const NoFeaturedWrapper = styled('div')`
  ${noFeaturedWrapper}
  margin: 0;
  .breadcrumbs, h1 {
    position: static;
    margin: 0 10% 30px;
  }
  
  @media (max-width: ${breakpoints.sm}) {
    .breadcrumbs, h1 {
      margin: 0 0 20px;
    }
  }
  
`;

const ContentWrapper = styled('div')`
  ${wrapper}
  ${ContentStyles}
`;

const ListingWrapper = styled('div')`
  ul {
    list-style: none;
  }
  li {
    display: inline-block;
    vertical-align: top;
    width: 48%;
    margin: 0 4% 10px 0;
    &:nth-of-type(2n+0) {
      margin-right: 0;
    }
  }
  .game-title {
    display: inline-block;
    vertical-align: middle;
    padding: 20px;
    font-size: 1.2rem;
  }
  @media (max-width: ${breakpoints.sm}) {
    .game-title {
      display: block;
      padding: 0;
      margin-bottom: 25px;
      text-align: center;
      font-size: 1rem;
    }
  }
`;


const ImgWrap = styled('div')`
    display: inline-block;
    vertical-align: middle;
    width: 194px;
    text-align: center;
    img {
        border: 1px solid ${color.darkGrey};
    }
    @media (max-width: ${breakpoints.sm}) {
        display: block;
        padding: 0;
        margin:0 auto 10px;
        width: auto;
    }
`;


const PostTemplate = ({ data, pageContext }) => {
  const postTypes = data.allWordpressWpDemo;
  const placeholder = data.placeholder;
  const breadcrumbs = pageContext.breadcrumbs;
  const page_title = pageContext.page_title;

  return (
    <Layout>
      <SEO
        title={pageContext.metaTitle}
        description={pageContext.metaDesc}
        pathname={"/" + pageContext.posttype + "/"}
        breadcrumbs={breadcrumbs}
      />
      <WrapperDesktop>
        <NoFeaturedWrapper>
          <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title}/>
          <h1>{pageContext.h1}</h1>
        </NoFeaturedWrapper>

        <ListingWrapper>
          <ul>
            {postTypes.edges.map((item) =>
              <li key={item.node.wordpress_id}>
                <Link to={pageContext.posttype + "/" + item.node.slug + "/"}>
                  {(item.node.featured_media && item.node.featured_media.localFile && item.node.featured_media.localFile.childImageSharp.fluid) ?
                    <ImgWrap>
                      <Img fluid={item.node.featured_media.localFile.childImageSharp.fluid}
                           alt={item.node.featured_media.alt_text ? item.node.featured_media.alt_text : item.node.title}/>
                    </ImgWrap>
                    : <ImgWrap>
                      <Img fixed={data.placeholder.childImageSharp.fixed} alt={item.node.title}/>
                    </ImgWrap>
                  }
                  <span className="game-title" dangerouslySetInnerHTML={{ __html: item.node.title }}/>
                </Link>
              </li>
            )}
          </ul>
        </ListingWrapper>
        <ContentWrapper>
          {pageContext.content}
        </ContentWrapper>
      </WrapperDesktop>
    </Layout>
  );
};

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array
};

export default PostTemplate;

export const pageQuery = graphql`
 query {
   allWordpressWpDemo {
     edges {
       node {
         wordpress_id
         id
         title
         link
         slug
         content
         excerpt
         path
         date(formatString: "MMMM DD, YYYY")
         template
         type
         yoast_meta {
           yoast_wpseo_title
           yoast_wpseo_metadesc
           yoast_wpseo_canonical
         }
         featured_media {
           alt_text
           localFile {
             childImageSharp {
               fluid(maxWidth: 430) {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }
         }                    

       }
     }
   }
   placeholder: file(relativePath: { eq: "placeholder.png" }) {
     childImageSharp {
       fixed(width: 190, height: 190) {
         ...GatsbyImageSharpFixed
       }
     }
   }
 }
`;
